import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withLocation from "./withLocation"
import Listing, { MeetingSpaceFilter } from "./listing"
import SearchFormAlter from "./search/searchalter"
import { navigate } from "gatsby"

const SearchComponent = ({ search }) => {
  var { city } = search
  var { lat } = search
  var { lng } = search
  var { spaceType } = search
  const [listingFilter, setlistingFilter] = useState([])
  const [inCity, setinCity] = useState([])
  const [screenSize, setScreenSize] = useState([])
  if (!lat) {
    city = "Bengaluru"
    lat = "12.968367"
    lng = "77.5953411"
    spaceType = "dailyofficeSpace"
  }

  useEffect(() => {
    if (listingFilter == "") {
      setlistingFilter(spaceType)
    }
  }, [listingFilter])

  useEffect(() => {
    if (inCity == "") {
      setinCity(city)
    }
  })

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  useEffect(() => {
    function handleResize() {
      const { width } = getWindowDimensions()

      if (width >= 980 && width < 1440) {
        setScreenSize("medium")
      } else if (width >= 1440 && width < 1981) {
        setScreenSize("large")
      } else if (width >= 1981) {
        setScreenSize("xlarge")
      } else {
        setScreenSize("small") // You might want to handle cases smaller than 980px
      }
    }

    window.addEventListener("resize", handleResize)

    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return (
    <div className="row ">
      <div className="container-fluid noPadding">
        <div className="container ">
          <div className={"row " + screenSize}>
            {screenSize === "large" ? (
              <div className="col-md-1"></div>
            ) : (
              <div className="col-md-2"></div>
            )}

            <div className="col-md-10 ">
              <h1 className="listingpageTitle">Search Results</h1>

              <SearchFormAlter
                placeholder={inCity}
                spacetype="dailyofficeSpace"
              />

              <div className="filterbar" style={{ marginTop: 15 }}>
                <ul className="SearchListingFilter scrollmenu">
                  <li>
                    <a
                      onClick={e => {
                        setlistingFilter("meetingSpace")
                        var url =
                          "/search/?city=" +
                          city +
                          "&lat=" +
                          lat +
                          "&lng=" +
                          lng +
                          "&spaceType=meetingSpace"
                        navigate(url)
                      }}
                      className={
                        listingFilter === "meetingSpace" ? "active" : ""
                      }
                    >
                      Meeting Spaces
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={e => {
                        setlistingFilter("monthlyofficeSpace")
                        var url =
                          "/search/?city=" +
                          city +
                          "&lat=" +
                          lat +
                          "&lng=" +
                          lng +
                          "&spaceType=monthlyofficeSpace"
                        navigate(url)
                      }}
                      className={
                        listingFilter === "monthlyofficeSpace" ? "active" : ""
                      }
                    >
                      Monthly Office Space
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={e => {
                        setlistingFilter("dailyofficeSpace")
                        var url =
                          "/search/?city=" +
                          city +
                          "&lat=" +
                          lat +
                          "&lng=" +
                          lng +
                          "&spaceType=dailyofficeSpace"
                        navigate(url)
                      }}
                      className={
                        listingFilter === "dailyofficeSpace" ? "active" : ""
                      }
                    >
                      Coworking Day Pass
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {city !== "" &&
        lat !== "" &&
        lng !== "" &&
        spaceType === "dailyofficeSpace" ? (
          <Listing
            city={city}
            lat={lat}
            lng={lng}
            spacetype="dailyofficeSpace"
          />
        ) : (
          ""
        )}
        {city !== "" &&
        lat !== "" &&
        lng !== "" &&
        spaceType === "monthlyofficeSpace" ? (
          <Listing
            className="monthlyspace"
            city={city}
            lat={lat}
            lng={lng}
            spacetype="monthlyofficeSpace"
          />
        ) : (
          ""
        )}
        {city !== "" &&
        lat !== "" &&
        lng !== "" &&
        spaceType === "meetingSpace" ? (
          <Listing city={city} lat={lat} lng={lng} spacetype="meetingSpace" />
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

SearchComponent.propTypes = {
  search: PropTypes.object,
}

export default withLocation(SearchComponent)
